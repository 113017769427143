<template>
  <ClientOnly>
    <RevContainer class="py-60">
      <RevForm :initial-values="initialValues" @submit="refreshSettings">
        <template #default="{ values }">
          <div class="flex h-full max-w-400 flex-col gap-12">
            <RevInputSelect
              id="current-language"
              v-model="values.currentLanguage"
              label="Current language"
              :options="[
                { label: 'French (FR)', value: 'fr-fr' },
                { label: 'English (US)', value: 'en-us' },
              ]"
            />

            <RevInputText
              id="BMUserId"
              v-model="values.BMUserId"
              class="mt-12"
              label="Client ID"
            />

            <RevInputText
              id="userEmail"
              v-model="values.userEmail"
              class="mt-12"
              label="User Email"
            />

            <RevInputText
              id="userName"
              v-model="values.userName"
              class="mt-12"
              label="User name"
            />

            <RevInputText
              id="orderlineId"
              v-model="values.orderlineId"
              class="mt-12"
              label="Orderline Id"
            />

            <RevInputSelect
              id="customerIssue"
              v-model="values.customerIssue"
              class="mt-12"
              label="Customer Issue Category"
              :options="[
                { label: 'Technical', value: 'technical' },
                { label: 'Delivery', value: 'delivery' },
              ]"
            />
          </div>

          <RevButton class="mt-12" type="submit" variant="primary">
            Refresh Live Chat settings
          </RevButton>
        </template>
      </RevForm>
    </RevContainer>
  </ClientOnly>
</template>

<script setup lang="ts">
import { navigateTo, useRoute, useRuntimeConfig } from '#imports'
import { computed, ref } from 'vue'

import { RevButton } from '@ds/components/Button'
import { RevContainer } from '@ds/components/Container'
import { RevForm } from '@ds/components/Form'
import { RevInputSelect } from '@ds/components/InputSelect'
import { RevInputText } from '@ds/components/InputText'

import { ROUTES } from '../../routes.constants'
import { usePostSalesZendesk } from '../../useZendesk'

const route = useRoute()

const query = computed(() => route.query)
const config = useRuntimeConfig()

const widgetPostSalesUrl = `https://static.zdassets.com/ekr/snippet.js?key=${config.public.FF_ENABLE_CARE_ZENDESK_POST_SALES_KEY}`

const getdataFromQueryParameters = ({
  name,
  defaultValue,
}: {
  name: string
  defaultValue: string
}) => {
  return query.value[name] ? (query.value[name] as string) : defaultValue
}

type FormValues = {
  currentLanguage: string
  BMUserId: string
  userEmail: string
  userName: string
  orderlineId: string
  customerIssue: string
}

const initialValues = ref<FormValues>({
  currentLanguage: getdataFromQueryParameters({
    name: 'currentLanguage',
    defaultValue: 'en-us',
  }),
  BMUserId: getdataFromQueryParameters({
    name: 'BMUserId',
    defaultValue: '12345',
  }),
  userEmail: getdataFromQueryParameters({
    name: 'userEmail',
    defaultValue: 'john.doe@gmail.com',
  }),
  userName: getdataFromQueryParameters({
    name: 'userName',
    defaultValue: 'John Doe',
  }),
  orderlineId: getdataFromQueryParameters({
    name: 'orderlineId',
    defaultValue: '9876',
  }),
  customerIssue: getdataFromQueryParameters({
    name: 'customerIssue',
    defaultValue: 'technical',
  }),
})

usePostSalesZendesk(widgetPostSalesUrl, initialValues.value)

const refreshSettings = async ({
  currentLanguage,
  BMUserId,
  userEmail,
  userName,
  orderlineId,
  customerIssue,
}: FormValues) => {
  await navigateTo({
    name: ROUTES.ZENDESK.CUSTOMER_LIVE_CHAT,
    query: {
      currentLanguage,
      BMUserId,
      userEmail,
      userName,
      orderlineId,
      customerIssue,
    },
  })

  window.location.reload()
}
</script>
